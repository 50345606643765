var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    staticClass: "app--main",
    attrs: {
      "isMain": ""
    }
  }, [_c('main-visual'), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }