<template>
    <app-primary isMain class="app--main">
        <main-visual />
        <popup-layer />
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import MainVisual from "@/sets/styles/mains/main-visual.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";

export default {
    components: {
        AppPrimary,
        MainVisual,
        PopupLayer,
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
