var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-footer-basic', [_c('u-footer-body-basic', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--lg"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('logo-primary', [_vm._v("CS RENEWABLES")])], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-medium', {
          staticClass: "txt--sm txt--dark pb-4px pb-md-12px"
        }, [_vm._v("CS Renewables, Inc.")]), _c('footer-info')], 1), _c('v-col', {
          staticClass: "mt-n2px mt-md-n6px",
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-default', {
          staticClass: "txt--sm"
        }, [_vm._v(" Copyright 2023. All Rights Reserved.")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }