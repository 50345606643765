<template>
    <u-inline-group>
        <li v-for="info in infos" :key="info.title" :class="info.clear == true ? 'clear' : ''">
            <u-txt-default class="txt--sm">
                {{ info.title }}: <span>{{ info.text }}</span>
            </u-txt-default>
        </li>
    </u-inline-group>
</template>

<script>
import UInlineGroup from "@/components/publish/styles/groups/u-inline-group.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UInlineGroup,
        UTxtDefault,
    },
    data() {
        return {
            infos: [
                { title: "Phone", text: "(201) 429-2027" },
                { title: "Fax", text: "(201) 429-2207" },
                { title: "Email", text: "info@csrenewables.us" },
                { title: "Address", text: "1 Bridge Plaza, Suite 665, Fort Lee, NJ 07024", clear: true },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
