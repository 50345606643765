import { render, staticRenderFns } from "./u-btn-icon-outlined-rounded.vue?vue&type=template&id=45d7263c&scoped=true&"
import script from "./u-btn-icon-outlined-rounded.vue?vue&type=script&lang=js&"
export * from "./u-btn-icon-outlined-rounded.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d7263c",
  null
  
)

export default component.exports