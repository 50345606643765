var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-chevron",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 14 14"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M9.6775 8.98917L7 6.3175L4.3225 8.98917L3.5 8.16667L7 4.66667L10.5 8.16667L9.6775 8.98917Z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-chevron",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 14 14"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M8.98828 4.3225L6.31661 7L8.98828 9.6775L8.16578 10.5L4.66578 7L8.16578 3.5L8.98828 4.3225Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }