<template>
    <u-footer-basic>
        <u-footer-body-basic>
            <template #left>
                <v-row class="row--lg">
                    <v-col cols="12">
                        <logo-primary>CS RENEWABLES</logo-primary>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-medium class="txt--sm txt--dark pb-4px pb-md-12px">CS Renewables, Inc.</u-txt-medium>
                        <footer-info />
                    </v-col>
                    <v-col cols="12" class="mt-n2px mt-md-n6px">
                        <u-txt-default class="txt--sm"> Copyright 2023. All Rights Reserved.</u-txt-default>
                    </v-col>
                </v-row>
            </template>
        </u-footer-body-basic>
    </u-footer-basic>
</template>

<script>
import LogoPrimary from "@/components/publish/parents/headers/logo-primary.vue";

import UFooterBasic from "@/sets/styles/footers/u-footer-basic.vue";
import UFooterBodyBasic from "@/sets/styles/footers/u-footer-body-basic.vue";

import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import FooterInfo from "@/components/publish/styles/footers/footer-info.vue";

export default {
    components: {
        LogoPrimary,

        UFooterBasic,
        UFooterBodyBasic,

        UTxtDefault,
        UTxtMedium,
        FooterInfo,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
