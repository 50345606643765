<template>
    <h2 v-bind="$attrs" class="tit"><slot /></h2>
</template>

<script>
export default {
    props: {
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit {
    font-size: var(--tit-font-size);
    font-weight: var(--tit-font-weight);
    line-height: var(--tit-line-height);
    word-break: keep-all;
    // size
    &--xxs {
        font-size: var(--tit-font-size-xxs);
    }
    &--xs {
        font-size: var(--tit-font-size-xs);
    }
    &--sm {
        font-size: var(--tit-font-size-sm);
    }
    &--lg {
        font-size: var(--tit-font-size-lg);
    }
    &--xl {
        font-size: var(--tit-font-size-xl);
    }
    &--xxl {
        font-size: var(--tit-font-size-xxl);
    }
}
</style>
