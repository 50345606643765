<template>
    <div class="main-visual">
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide v-for="detail in slide" :key="detail">
                <div class="main-visual__img" :style="'background-image: url('+detail+');'" />
            </swiper-slide>
        </swiper>
        <div class="main-visual__inner">
            <v-container class="container--lg">
                <div class="mb-40px mb-md-80px">
                    <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--xxl line-height-1 white--text">CS Renewables</u-tit-default>
                    <u-txt-default data-aos="fade-up" data-aos-delay="300" class="txt--xl white--text mt-8px mt-md-16px">CS RENEWABLES, INC. </u-txt-default>
                </div>
                <swiper-pagination-primary class="swiper-pagination--left swiper-pagination--light" />
            </v-container>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import SwiperPaginationPrimary from "@/components/publish/parents/swipers/swiper-pagination-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        SwiperPaginationPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
    },
    props: {},
    data() {
        return {
            swiperOptions: {
                effect: "fade",
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            },
            slide: [
                "/images/main/visual.jpg",
                "/images/main/visual2.jpg",
                "/images/main/visual3.jpg",
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    width: 100%;
    max-width: 1920px;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    &__inner {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 10;
        ::v-deep{
            .swiper-pagination{
                position: relative;
                left: 0;
                bottom: 0;
            }
        }

    }
    &__img {
        max-width: 100%;
        width: 100%;
        height: calc(100vh - var(--header-height));
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: scale(1.2);
        transform-origin: 70% center;
        transition: 5.2s ease-in-out;
    }    
    .swiper-slide-active{
        .main-visual__img{
            transform: scale(1);
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    // main-visual
    .main-visual {
        &__inner{
            padding-top: var(--header-height);
        }
        &__img {
            height: 100vh;
        }
    }
}
</style>
