<template>
    <svg v-if="direction == 'up' || direction == 'down'" v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" class="icon-svg icon-chevron" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M9.6775 8.98917L7 6.3175L4.3225 8.98917L3.5 8.16667L7 4.66667L10.5 8.16667L9.6775 8.98917Z" />
    </svg>
    <svg v-else v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" class="icon-svg icon-chevron" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M8.98828 4.3225L6.31661 7L8.98828 9.6775L8.16578 10.5L4.66578 7L8.16578 3.5L8.98828 4.3225Z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "right" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
}
.icon--size {
    &-xx-small {
        width: 8px;
    }
    &-x-small {
        width: 10px;
    }
    &-small {
        width: 12px;
    }
    &-default {
        width: 14px;
    }
    &-large {
        width: 16px;
    }
    &-x-large {
        width: 20px;
    }
    &-xx-large {
        width: 24px;
    }
}
</style>
