var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, _vm._l(_vm.slide, function (detail) {
    return _c('swiper-slide', {
      key: detail
    }, [_c('div', {
      staticClass: "main-visual__img",
      style: 'background-image: url(' + detail + ');'
    })]);
  }), 1), _c('div', {
    staticClass: "main-visual__inner"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('div', {
    staticClass: "mb-40px mb-md-80px"
  }, [_c('u-tit-default', {
    staticClass: "tit--xxl line-height-1 white--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v("CS Renewables")]), _c('u-txt-default', {
    staticClass: "txt--xl white--text mt-8px mt-md-16px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }, [_vm._v("CS RENEWABLES, INC. ")])], 1), _c('swiper-pagination-primary', {
    staticClass: "swiper-pagination--left swiper-pagination--light"
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }