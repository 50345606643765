<template>
    <swiper-pagination v-bind="$attrs" class="swiper-pagination--line"><slot /></swiper-pagination>
</template>

<script>
import SwiperPagination from "@/components/publish/styles/swipers/swiper-pagination.vue";

export default {
    props: {},
    components: { SwiperPagination },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.swiper-pagination--line {
    ::v-deep {
        .swiper-pagination {
            &-bullet {
                width: 30px;
                height: 2px;
                margin: 0 2px;
                border: 0;
                border-radius: 0;
                background-color: rgba(255, 255, 255, .4);
                opacity: 1;
                overflow: hidden;
                &::before{
                    display: none;
                }
                &::after{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    top: 0;
                    left: 0;
                    transform: translateX(-100%);
                    transition: 0;
                    
                }
                &-active{
                    &::after{
                        transform: translateX(0);
                        animation: move forwards 5s linear;
                    }
                }
            }
        }
    }

    &.swiper-pagination {
        &--sm {
            ::v-deep {
                .swiper-pagination {
                    &-bullet {
                        width: 20px;
                        margin: 0 2px;
                    }
                }
            }
        }
    }
}
@keyframes move{
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .swiper-pagination--line {
        ::v-deep {
            .swiper-pagination {
                &-bullet {
                    width: 60px;
                    margin: 0 4px;
                }
            }
        }

        &.swiper-pagination {
            &--sm {
                ::v-deep {
                    .swiper-pagination {
                        &-bullet {
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
