<template>
    <u-header-fixed>
        <!-- S: Header -->
        <u-header-body-basic class="d-none d-xl-block">
            <template #left>
                <logo-primary>CS RENEWABLES</logo-primary>
            </template>
            <template #right>
                <u-gnb-basic class="text-center" gnbName="gnb" lnbTag="u-lnb-topline-border" />
            </template>
        </u-header-body-basic>
    </u-header-fixed>
</template>

<script>
import LogoPrimary from "@/components/publish/parents/headers/logo-primary.vue";

import UHeaderFixed from "@/sets/styles/headers/u-header-fixed.vue";
import UHeaderBodyBasic from "@/sets/styles/headers/u-header-body-basic.vue";

import Gnb from "@/components/publish/styles/headers/gnb.vue";
import UGnbBasic from "@/components/publish/styles/headers/u-gnb-basic.vue";

export default {
    components: {
        LogoPrimary,

        UHeaderFixed,
        UHeaderBodyBasic,

        Gnb,
        UGnbBasic,
    },
    data() {
        return {};
    },
    mounted() {},
    // methods: {
    //     logout() {
    //         if (confirm("로그아웃 하시겠습니까?")) {
    //             this.$store.dispatch("logout");
    //         }
    //     },
    // },
    // computed: {
    //     accessToken() {
    //         return this.$store.state.accessToken;
    //     },
    // },
};
</script>
